import React, { useEffect, useState } from "react";
import Modal from "../../molecules/modal";
import Button from "../../atoms/button";
import { useWallet } from "../../../context/WalletConnect/WalletConnect";

const TermsModal = () => {
  const { isWalletConnected } = useWallet();
  const [show, setShow] = useState(false);
  const [checkBox1, setCheckBox1] = useState(false);
  const [checkBox2, setCheckBox2] = useState(false);
  const [checkBox3, setCheckBox3] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  useEffect(() => {
    if (sessionStorage.getItem("terms") !== "hide" && isWalletConnected) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [isWalletConnected]);

  const handleClose = () => {
    sessionStorage.setItem("terms", "hide");
    setShow(false);
  };

  const handleCheckBox = (evt: any) => {
    setCheckAll((state) => !state);
    setCheckBox1(evt.target.checked),
      setCheckBox2(evt.target.checked),
      setCheckBox3(evt.target.checked);
  };

  return (
    <Modal
      show={show}
      onClose={handleClose}
      header="Terms & Privacy policy"
      className="termsModal"
      staticBackDrop={true}
      closeButton={false}
    >
      <div className="content max-h-[400px] overflow-auto px-8">
        <p className={"mb-2 text-light-emphasis"}>
          <span className={"font-medium"}>Important Disclaimer:</span> Before
          proceeding, please confirm your agreement to the terms by checking the
          boxes below. If you do not agree, please leave the website:
        </p>
        <div className={"flex items-center mb-3"}>
          <label className={"mr-4 -mb-1 "}>
            <input
              type="checkbox"
              checked={checkBox1}
              onChange={() => {
                setCheckBox1((state) => !state);
                if (checkAll) {
                  setCheckAll(false);
                }
              }}
            />
          </label>
          <p className={" text-light-500 text-sm"}>
            I have read and understood, and hereby agree to be legally bound as
            a ‘User’ to all the terms contained in the{" "}
            <a
              href="https://pstake.finance/terms"
              target="_blank"
              rel="noreferrer"
              className={"underline"}
            >
              Terms and Conditions
            </a>
            (including the{" "}
            <a
              href="https://pstake.finance/privacy"
              target="_blank"
              rel="noreferrer"
              className={"underline"}
            >
              Privacy Policy
            </a>
            ) without qualification.
          </p>
        </div>
        <div className={"flex items-center mb-3"}>
          <label className={"mr-4 -mb-1"}>
            <input
              type="checkbox"
              checked={checkBox2}
              onChange={() => {
                setCheckBox2((state) => !state);
                if (checkAll) {
                  setCheckAll(false);
                }
              }}
            />
          </label>
          <p className={" text-light-500 text-sm"}>
            I declare that I am not an Excluded Person as defined in the{" "}
            <a
              href="https://pstake.finance/terms"
              target="_blank"
              rel="noreferrer"
              className={"underline"}
            >
              Terms and Conditions.
            </a>
          </p>
        </div>
        <div className={"flex items-center mb-3"}>
          <label className={"mr-4 -mb-1"}>
            <input
              type="checkbox"
              checked={checkBox3}
              onChange={() => {
                setCheckBox3((state) => !state);
                if (checkAll) {
                  setCheckAll(false);
                }
              }}
            />
          </label>
          <p className={" text-light-500 text-sm"}>
            I agree that my use and continued use of this site is subject to my
            continued agreement to the prevailing{" "}
            <a
              href="https://pstake.finance/terms"
              target="_blank"
              rel="noreferrer"
              className={"underline"}
            >
              Terms and Conditions
            </a>{" "}
            (which may change from time to time) and will apply to all actions I
            take on the site without requiring my confirmation in each specific
            instance.
          </p>
        </div>
        <div className={"flex items-center mb-3"}>
          <label className={"mr-4 -mb-1"}>
            <input
              type="checkbox"
              checked={checkBox1 && checkBox2 && checkBox3}
              onChange={handleCheckBox}
            />
            <span className="checkmark"></span>
          </label>
          <p className={" text-light-500 text-sm"}>Select All</p>
        </div>
      </div>
      <div className="text-center mb-4">
        <Button
          className="button md:py-2 md:text-sm m-auto !w-[200px]"
          type="primary"
          size="medium"
          disabled={!checkBox1 || !checkBox2 || !checkBox3}
          onClick={handleClose}
          content="Continue"
        />
      </div>
    </Modal>
  );
};

export default TermsModal;
